import ListingApi from 'pages/api/listing';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategorySectionHeader from '../CategorySectionHeader';
import Container from '../Container';
import Grid from '../grid/Grid';
import ProductCard1 from '../product-cards/ProductCard1';
import { useSession } from 'next-auth/react';

export interface ExploreProductsProps {
  initialListings?: any;
}

const ExploreProducts: React.FC<ExploreProductsProps> = ({ initialListings }) => {
  const [listings, setListings] = useState(initialListings);
  const [pageNumber, setPageNumber] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const { data: session, status } = useSession();
 const loading = status === 'loading';;

  const getMoreListings = async () => {
    if (session) {
      setLoggedInUserId(session.userId);
    }
    setPageNumber(pageNumber + 1);
    const res = await ListingApi.getListings(10, pageNumber, loggedInUserId);
    setListings([...listings, ...res.data?.data]);
    setHasMore(res.data.lastPage === pageNumber ? false : true);
  };

  return (
    <Container mb="70px">
      <CategorySectionHeader title="More For You" />
      <InfiniteScroll
        dataLength={listings.length}
        loader={
          <p style={{ textAlign: 'center', paddingTop: '50px' }}>
            <b> Loading ... ⏳ ⏳</b>
          </p>
        }
        hasMore={hasMore}
        next={getMoreListings}
        style={{ overflow: 'hidden' }}
        endMessage={
          <p style={{ textAlign: 'center', paddingTop: '50px' }}>
            <b>Yay! You have seen it all 🎉 🎉</b>
          </p>
        }
      >
        <Grid container spacing={6}>
          {listings.map((item, ind) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={ind}>
              <ProductCard1 {...item} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

export default ExploreProducts;
