import Image from '@component/Image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import { getTheme } from '../../utils/utils';
import Box from '../Box';
import Container from '../Container';
import FlexBox from '../FlexBox';
import Grid from '../grid/Grid';
import Icon from '../icon/Icon';
import Typography, { Paragraph } from '../Typography';

const StyledLink = styled.a`
  position: relative;
  display: block;
  padding: 0.3rem 0rem;
  color: ${getTheme('colors.gray.white')};
  cursor: pointer;
  border-radius: 4px;
  :hover {
    color: ${getTheme('colors.gray.100')};
  }
`;

const Footer: React.FC = () => {
  return (
    <footer>
      <Box bg="#328F59">
        <Container p="1rem" color="white">
          <Box py="5rem" overflow="hidden">
            <Grid container spacing={6}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Link href="/">
                  <a>
                    <Image mb="1.25rem" src="/assets/images/logo.svg" alt="logo" />
                  </a>
                </Link>

                <Paragraph mb="1.25rem" color="gray.white">
                  Teakita believes in total commitment to our customers with customer satisfaction,
                  exquisite quality and prompt delivery as priorities.
                </Paragraph>

                {/* <AppStore /> */}
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={12}>
                <Typography fontSize="25px" fontWeight="600" mb="1.25rem" lineHeight="1">
                  About Us
                </Typography>

                <div>
                  {aboutLinks.map((item, ind) => (
                    <Link href={item.href} key={ind}>
                      <StyledLink>{item.title}</StyledLink>
                    </Link>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography fontSize="25px" fontWeight="600" mb="1.25rem" lineHeight="1">
                  Customer Care
                </Typography>

                <div>
                  {customerCareLinks.map((item, ind) => (
                    <Link href="/" key={ind}>
                      <StyledLink>{item}</StyledLink>
                    </Link>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography fontSize="25px" fontWeight="600" mb="1.25rem" lineHeight="1">
                  Contact Us
                </Typography>
                <Typography py="0.3rem" color="gray.white">
                  Teakita believes in total commitment to our customers with customer satisfaction,
                  exquisite quality and prompt delivery as priorities.
                </Typography>

                <FlexBox className="flex" mx="-5px">
                  {iconList.map((item) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopenner"
                      key={item.iconName}
                    >
                      <Box m="5px" size="small" p="10px" bg="rgba(0,0,0,0.2)" borderRadius="50%">
                        <Icon size="12px" defaultcolor="auto">
                          {item.iconName}
                        </Icon>
                      </Box>
                    </a>
                  ))}
                </FlexBox>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

const aboutLinks = [
  { title: 'Careers', href: '/' },
  { title: 'Our Stores', href: '/' },
  { title: 'Our Cares', href: '/' },
  { title: 'Terms & Conditions', href: '/' },
  { title: 'Privacy Policy', href: '/privacy_policy' },
];

const customerCareLinks = [
  'Help Center',
  'How to Buy',
  'Track Your Order',
  'Corporate & Bulk Purchasing',
  'Returns & Refunds',
];

const iconList = [
  { iconName: 'facebook', url: 'https://www.facebook.com/teakitamy/?locale=id_MY' },
  { iconName: 'twitter', url: 'https://twitter.com/teakita' },
  { iconName: 'waze', url: 'https://waze.com/ul/hw281x4w6f' },
  { iconName: 'whatsapp', url: 'https://wa.me/60122922826' },
  { iconName: 'instagram', url: 'https://www.instagram.com/teakita/?igshid=ZDdkNTZiNTM%3D' },
];

export default Footer;
