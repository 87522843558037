import React, { useEffect, useState } from 'react';
import Container from '../Container';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import Image from '../Image';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import NavLink from '../nav-link/NavLink';
import { Small } from '../Typography';
import StyledTopbar from './Topbar.style';

const Topbar: React.FC = () => {

  useEffect(() => {
    // get language from browser
    // console.log(navigator.language);
  }, []);

  return (
    <StyledTopbar>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <FlexBox className="topbar-left">
          <div className="logo">
            <img src="/assets/images/logo.svg" alt="logo" />
          </div>
          <FlexBox alignItems="center">
            <Icon size="14px">phone-call</Icon>
            <span>+(60)11 2614 0114</span>
          </FlexBox>
          <FlexBox alignItems="center" ml="20px">
            <Icon size="14px">mail</Icon>
            <span>support@teakita.com</span>
          </FlexBox>
        </FlexBox>
      </Container>
    </StyledTopbar>
  );
};

const languageList = [
  {
    title: 'EN',
    imgUrl: '/assets/images/flags/usa.png',
  },
  {
    title: 'BN',
    imgUrl: '/assets/images/flags/bd.png',
  },
  {
    title: 'HN',
    imgUrl: '/assets/images/flags/in.png',
  },
];

const currencyList = [
  {
    title: 'USD',
    imgUrl: '/assets/images/flags/usa.png',
  },
  {
    title: 'EUR',
    imgUrl: '/assets/images/flags/uk.png',
  },
  {
    title: 'BDT',
    imgUrl: '/assets/images/flags/bd.png',
  },
  {
    title: 'INR',
    imgUrl: '/assets/images/flags/in.png',
  },
];

export default Topbar;
