import React from 'react';
import Button from '../buttons/Button';
import Typography from '../Typography';
import { StyledCarouselCard1 } from './CarouselCardStyle';
import { CardBackground } from './CardBackgroundStyle';

export interface CarouselCard1Props {}

const CarouselCard1: React.FC<CarouselCard1Props> = () => {
  return (
    <CardBackground>
      <StyledCarouselCard1>
        <div className="text-container">
          <Typography fontSize="50px" lineHeight="60px" fontWeight="700">
            Get 5% Off For Your First Purchase
          </Typography>
          <Typography color="secondary.main" mb="1.35rem">
            Enjoy the hassle free experience of buying through us! Doesn’t fit well in your space?
            Return it within your first 3 days of purchase, no questions asked!
          </Typography>
          <Button className="button-link" variant="contained" color="primary" p="1rem 1.5rem">
            Shop Now !
          </Button>
        </div>

        {/* <div className="image-holder">
        <img src="/assets/images/banners/a.png" alt="apple-watch-1" />
      </div> */}
      </StyledCarouselCard1>
    </CardBackground>
  );
};

export default CarouselCard1;
