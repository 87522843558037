import Box from '@component/Box';
import HoverBox from '@component/HoverBox';
import LazyImage from '@component/LazyImage';
import { H4 } from '@component/Typography';
import Link from 'next/link';
import React from 'react';
export interface ProductCard5Props {
  imgUrl: string;
  title: string;
  firstName: string;
  lastName: string;
  imageLink: string;
  id: string;
}
const ProductCard5: React.FC<ProductCard5Props> = ({
  imgUrl,
  title,
  firstName,
  lastName,
  imageLink,
  id,
}) => {
  return (
    <Link href={`/shop/${id}`}>
      <Box>
        <HoverBox borderRadius={5} mb="0.5rem">
          <LazyImage
            loader={() => (imageLink !== null ? imageLink : '/assets/images/furniture/Lamp.png')}
            src={imageLink !== null ? imageLink : '/assets/images/furniture/Lamp.png'}
            width={325}
            height={260}
            layout="responsive"
            objectFit="cover"
            loading="lazy"
            alt={title}
          />
        </HoverBox>
        <H4 fontSize="14px" fontWeight="600">
          {firstName + ' ' + lastName}
        </H4>
      </Box>
    </Link>
  );
};

export default ProductCard5;
