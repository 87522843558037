import Box from '@component/Box';
import CarouselCard1 from '@component/carousel-cards/CarouselCard1';
import Carousel from '@component/carousel/Carousel';
import Container from '@component/Container';
import Navbar from '@component/navbar/Navbar';
import React, { Fragment } from 'react';

const Section1: React.FC = () => {
  return (
    <Fragment>
      <Navbar navListOpen={false} />
      <Box bg="gray.white" mb="3.75rem">
        <Container pb="2rem" ml="0" style={{ marginLeft: 0 }}>
          <Carousel
            totalSlides={5}
            visibleSlides={1}
            infinite={true}
            autoPlay={false}
            showDots={true}
            showArrow={false}
            spacing="0px"
            fullWidth={true}
          >
            <CarouselCard1 />
            <CarouselCard1 />
            <CarouselCard1 />
            <CarouselCard1 />
            <CarouselCard1 />
          </Carousel>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Section1;
