import styled from 'styled-components';

export const CardBackground = styled.div`
  background-image: url('/assets/images/landing/landing_bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh;
  object-fit: cover;
  padding-bottom: 2rem;
`;
