import Card from '@component/Card';
import ListingApi from 'pages/api/listing';
import { UsersApi } from 'pages/api';
import React from 'react';
import CategorySectionCreator from '../CategorySectionCreator';
import Grid from '../grid/Grid';
import ProductCard2 from '../product-cards/ProductCard2';
import ProductCard5 from '../product-cards/ProductCard5';

export interface TeakitaPreferredProps {
  preferredUsers?: any;
}

const TeakitaPreferred: React.FC<TeakitaPreferredProps> = ({preferredUsers}) => {
  return (
    <CategorySectionCreator
      //   iconName="new-product-1"
      title="Teakita Preferred"
      seeMoreLink="/preferred"
    >
      <Card p="1rem">
        <Grid container spacing={6}>
          {preferredUsers.map((item) => (
            <Grid item lg={2} md={3} sm={4} xs={6} key={item.title}>
              <ProductCard5 {...item}/>
            </Grid>
          ))}
        </Grid>
      </Card>
    </CategorySectionCreator>
  );
};

export default TeakitaPreferred;


export async function getServerSideProps() {
  let listings = [];
  let preferredUsers = [];
  let pageSize = 10;
  try {
    const { status, data } = await ListingApi.getListings(pageSize, 1);
    listings = data.data;
  } catch (error) {
    console.log('error home page get listings', error);
  }

  try {
    const { status, data } = await UsersApi.getAllUsers(true, 6, 1);
    preferredUsers = data.data;
  } catch (error) {
    console.log('error home page get preferred', error);
  }

  return {
    props: {
      listings,
      preferredUsers,
    },
  };
}
