import Avatar from '@component/avatar/Avatar';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import Box from '../Box';
import { Chip } from '@component/Chip';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import Rating from '../rating/Rating';
import { SemiSpan, Paragraph } from '../Typography';
import { StyledProductCard1 } from './ProductCardStyle';
import Image from '../Image';
import { CardProps } from '@component/Card';
import ListingApi from 'pages/api/listing';
import { useSession } from 'next-auth/react';
import { OrdersApi } from 'pages/api';

export interface ProductCard1Props extends CardProps {
  className?: string;
  style?: CSSProperties;
  imageLink?: string;
  title?: string;
  price?: number;
  discount?: string;
  rating?: number;
  id?: string;
  User?: any;
  Files?: any[];
  deliveryType?: string;
  WishLists?: any[];
  Ratings?: any[];
}

const ProductCard1: React.FC<ProductCard1Props> = ({
  id,
  imageLink,
  Files,
  title,
  price,
  discount,
  rating,
  User,
  deliveryType,
  WishLists,
  Ratings,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [isWishList, setIsWishList] = useState(WishLists?.length > 0 ? true : false);
  const { data: session, status } = useSession();
 const loading = status === 'loading';;
  const [reviews, setReviews] = useState([]);

  const reviewlength = Ratings?.length;
  const totalRatings = Ratings?.reduce((a, b) => Number(a) + Number(b.rating), 0);
  const ratings = reviewlength > 0 ? (totalRatings / Ratings?.length).toFixed(1) : 0;

  const toggleDialog = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const manageFavItem = async () => {
    if (session?.accessToken) {
      // change from the backend
      let token = session?.accessToken;
      if (!isWishList) {
        try {
          await ListingApi.addToWishList(token, id);
          setIsWishList((isWishList) => !isWishList);
        } catch (err) {
          setIsWishList(false);
        }
      } else {
        try {
          await ListingApi.removeFromWishList(token, id);
          setIsWishList(false);
        } catch (err) {
          setIsWishList(false);
        }
      }
    } else {
      // JUST TOGGLE IN THE FRONTEND
      setIsWishList((isWishList) => !isWishList);
    }
  };

  return (
    <StyledProductCard1 {...props}>
      <div className="image-holder">
        <FlexBox position="absolute" flexDirection="row" alignItems="flex-start" paddingLeft="2px">
          {/* Teakita Preferred User */}
          {User && User.roleId === 3 && (
            <Chip
              bg="primary.main"
              color="primary.text"
              fontSize="10px"
              fontWeight="600"
              margin="3px"
            >
              <Icon variant="large" defaultcolor="auto">
                preferred-chip
              </Icon>
            </Chip>
          )}
          {/* Teakita Fulfillment */}
          {deliveryType === 'TEAK_FULFILMENT' && (
            <Chip
              bg="primary.main"
              color="primary.text"
              fontSize="10px"
              fontWeight="600"
              margin="3px"
            >
              <Icon variant="large" defaultcolor="auto">
                fulfilled-chip
              </Icon>
            </Chip>
          )}
        </FlexBox>
        <Box height={300}>
          <Link href={`/product/${id}`}>
            <a>
              <Image
                src={
                  Files?.length > 0
                    ? Files[0].fileUrl
                    : '/assets/images/furniture/placeholder-furniture.png'
                }
                width="100%"
                height="100%"
                alt={title}
                style={{ objectFit: 'cover' }}
              />
            </a>
          </Link>
        </Box>
      </div>

      <div className="details">
        <FlexBox alignItems="center" justifyContent="space-between" mt="10px">
          <Box>
            {reviewlength > 0 && <Rating value={Number(ratings)} outof={5} color="warn" readonly />}
          </Box>
          <Icon className="favorite-icon outlined-icon" variant="small" onClick={manageFavItem}>
            {isWishList ? 'heart_filled' : 'heart'}
          </Icon>
        </FlexBox>
        <FlexBox mt="10px">
          <Box flex="1 1 0" minWidth="0px" mr="0.5rem">
            <FlexBox alignItems="center">
              <SemiSpan pr="0.5rem" fontWeight="600" color="teakita.main">
                RM{(price - (price * Number(discount)) / 100).toFixed(2)}
              </SemiSpan>
              {!!discount && (
                <SemiSpan color="text.muted" fontWeight="600">
                  <del>{price}</del>
                </SemiSpan>
              )}
            </FlexBox>
          </Box>
        </FlexBox>
        <FlexBox justifyContent="center" mt="5px" mb="5px">
          <hr style={{ borderColor: '#fff', width: '90%' }} />
        </FlexBox>
        <FlexBox alignItems="center" justifyContent="space-between">
          <FlexBox justifyContent="space-between" alignItems="center">
            <Avatar
              src={
                User?.imageLink !== null
                  ? User?.imageLink
                  : '/assets/images/avatars/user_placeholder.png'
              }
              size={32}
              mr="15px"
            />
            <Paragraph>{User?.firstName}</Paragraph>
          </FlexBox>
          <Paragraph>{User?.averageRating !== 0.0 ? 'New User' : User?.averageRating}</Paragraph>
        </FlexBox>
      </div>
    </StyledProductCard1>
  );
};

export default ProductCard1;
