import ExploreProducts from '@component/home-1/ExploreProducts';
import { getSession } from 'next-auth/react';
import Section1 from '../components/home-1/Section1';
import TeakitaPreferred from '../components/home-1/teakita-preferred';
import AppLayout from '../components/layout/AppLayout';
import ListingApi from './api/listing';
import { UsersApi } from './api';
import 'rc-slider/assets/index.css';

const IndexPage = ({ listings, preferredUsers }) => {
  return (
    <main>
      <Section1 />
      <TeakitaPreferred preferredUsers={preferredUsers} />
      <ExploreProducts initialListings={listings} />
    </main>
  );
};

IndexPage.layout = AppLayout;

export default IndexPage;

export async function getServerSideProps(ctx) {
  const session = await getSession(ctx);
  let listings = [];
  let preferredUsers = [];
  let pageSize = 10;
  let loggedInUserId = null;
  try {
    if (session) {
      loggedInUserId = session.userId;
    }
    const { status, data } = await ListingApi.getListings(pageSize, 1, loggedInUserId);
    listings = data.data;
  } catch (error) {
    console.log('error home page get listings', error);
  }

  try {
    const { status, data } = await UsersApi.getAllUsers(true, 6, 1);
    preferredUsers = data.data;
  } catch (error) {
    console.log('error home page get preferred', error);
  }

  return {
    props: {
      listings,
      preferredUsers,
    },
  };
}
