import styled from 'styled-components';

export const StyledCarouselCard1 = styled.div`
  text-align: left;
  margin-left: 260px;
  margin-right: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 2rem;
  height: 100%;

  .image-holder {
    position: relative;
    //   width: 50%;
    img {
      width: 100%;
    }
  }

  .text-container {
    margin: 1rem;
    width: 40%;
  }

  @media only screen and (max-width: 900px) {
    margin-left: 136px;
    padding-left: 0px;

    .title {
      font-size: 32px;
    }

    .text-container {
      width: 100vw;
    }
  }

  @media only screen and (max-width: 425px) {
    .title {
      font-size: 16px;
    }
    .title + * {
      font-size: 13px;
    }
    .button-link {
      padding: 0.66rem 0.95rem;
      font-size: 13px;
    }
  }
`;
